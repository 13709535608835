import 'shepherd.js/dist/css/shepherd.css';
import './tour.css';
import Shepherd from 'shepherd.js';
import { offset } from '@floating-ui/dom';

export const tour = new Shepherd.Tour({
  useModalOverlay: false,
  defaultStepOptions: {
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 18 })],
    },
    cancelIcon: {
      enabled: true,
    },
  },
});

tour.addStep({
  id: 'zoom',
  text: 'Click on a tool to enable it',
  attachTo: {
    element: '[data-cy="Zoom"]',
    on: 'bottom',
  },
  buttons: [
    {
      text: 'Next',
      action: tour.next,
    },
  ],
});

const defaultButton = [
  {
    text: 'Next',
    action: tour.next,
  },
];

tour.addStep({
  id: 'click-and-drag-viewer',
  text: 'With a tool selected, click-and-drag anywhere on the main Dicom Viewer to use the tool.',
  attachTo: {
    element: 'canvas',
    on: 'left',
  },
  buttons: defaultButton,
});

tour.addStep({
  id: 'more-tools',
  text: 'More tools can be found by clicking the dropdown. Notable tools here include Stack Scroll, Cine, and Reference Lines.',
  attachTo: {
    element: '[data-cy="MoreTools-split-button-secondary"]',
    on: 'left',
  },
  buttons: defaultButton,
});

tour.addStep({
  id: 'select-view',
  text: 'Select a different view by double clicking, or click-and-drag the desired view on to the Dicom Viewer.',
  attachTo: {
    element: '[data-cy="study-browser-thumbnail"]',
    on: 'right',
  },
  buttons: [
    {
      text: 'Next',
      action: () => {
        !!parent && parent.postMessage('tour-done', '*');
        tour.complete();
      },
    },
  ],
});
